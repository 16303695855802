@import '../../sass/vars';

.presentation {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  background: $textDark;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;

  .button {
    z-index: 3;
    .name {
      font-size: 2.6rem;
      line-height: 32px;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }

  .play-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    background: $textDark;
    display: flex;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__buttons {
    position: fixed;
    padding: 10px;
    bottom: 0;
    display: flex;
    z-index: 9;
    .button {
      position: relative;
      margin: 0 20px;
      padding: 6px 10px;
      border: 0;
      width: 80px;
      &--clicked {
        background: $green;
        .name {
          color: $textDark;
        }
      }
      @media (max-width: 768px) {
        width: 60px;
        margin: 0 10px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .close {
    position: absolute;
    top: 30px;
    left: 0;
    width: 70px;
    z-index: 3;
    background: transparent;
    border: 0;
    padding-left: 30px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
    img {
      margin-right: 20px;
      height: 36px;
    }
    span {
      min-width: 360px;
      color: $white;
      font-size: 3.6rem;
      text-align: left;
      font-weight: 700;
      margin-top: -6px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &:hover {
      width: 360px;
    }
  }

  .video {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    > div {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
  > video {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  .a-enter-vr {
    display: none;
  }
  .drag-wrapper {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
      max-width: 540px;
    }
  }
}
