@import 'sass/vars';

.contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // z-index: 2;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    width: 210px;
    color: $white;
    background: $red;

    font-size: 20px;
    font-weight: 500;

    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 0;

    transition: opacity 0.1s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
    a {
      text-decoration: underline;
      color: $white;
      margin-left: 10px;
    }
    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    &__question {
      font-size: 2rem;
      line-height: 22px;
      font-weight: 400;
      position: absolute;
      bottom: calc(100% - 22px);
      left: -140px;
      color: $textDark;
      border: 4px solid $red;
      background: $white;
      border-radius: 20px 20px 0 20px;
      width: 180px;
      padding: 14px;
      text-align: left;
      box-shadow: -3px 3px 10px 2px rgba(0, 0, 0, 0.25);
      @media (max-width: 768px) {
        left: -10px;
        bottom: calc(100% - 4px);
      }
    }
    &--long {
      width: 310px;
    }
    &--meeting {
      a {
        text-decoration: none;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
