@import 'sass/vars';

.dustbin {
  position: absolute;
  z-index: 2;
  img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}
