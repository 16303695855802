@import '../../../sass/vars';

.console {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #282828;
  @media (max-width: 768px) {
    align-items: flex-end;
  }

  &__boxes {
    padding-right: 50px;
    z-index: 2;
    @media (max-width: 768px) {
      width: 100%;
      padding-right: 50px;
      padding-left: 10px;
      padding-bottom: 70px;
    }
  }
  &__instruction {
    z-index: 99999;
    color: $white;
    font-size: 3rem;
    text-align: center;
    text-shadow: 2px 2px $black;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
}
