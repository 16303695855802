.base-images {
  z-index: 1;
  video {
    visibility: visible;
    position: absolute;
  }
  img {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    &.animated {
      opacity: 0;
      animation: fade 2s linear 0.2s;
    }
  }
  &--show {
    img {
      visibility: visible;
    }
    video {
      visibility: hidden;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
