@import 'sass/vars';

.map {
  background: #d0d0d0; //
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  &__video {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    // max-height: 100vh;
    // max-width: 100vw;
    min-width: 1920px;
    min-height: 1080px;
  }
  &__eu-flags {
    position: absolute;
    top: 24px;
    right: 100px;
    height: 30px;
    width: auto;
    cursor: pointer;
    @media (max-width: 768px) {
      width: calc(100% - 100px);
      height: auto;
      left: 0;
      right: 0;
      top: 28px;
    }
  }
  &__audio-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: 0;
    outline: none;
    width: 50px;
    height: 42px;
    z-index: 3;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &__modal {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    .content {
      background: $white;
      padding: 40px 20px 20px;
      width: 500px;
      position: relative;
      @media (max-width: 768px) {
        width: calc(100% - 60px);
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        width: 40px;
        height: 40px;
        background: transparent;
        border: 0;
        font-size: 3rem;
        line-height: 20px;
        cursor: pointer;
      }
      p {
        margin: 0;
        margin-bottom: 40px;
      }
      img {
        width: 100%;
      }
    }
    &--hide {
      opacity: 0;
      transform: scale(0);
      pointer-events: none;
    }
    &--show {
      opacity: 1;
      transform: scale(1);
      pointer-events: initial;
    }
  }
}
