@import 'vars';
@import 'fonts';

body,
html {
  margin: 0;
  padding: 0;
  background: $white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  overscroll-behavior-x: none;
}

.button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $red;
  border-radius: 10px;
  box-shadow: -3px 3px 10px 2px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: 0;
  outline: none;
  .name {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 20px;
    color: $white;
  }

  .question {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    bottom: calc(100% - 4px);
    left: 50%;
    color: $textDark;
    border: 4px solid $red;
    background: $white;
    border-radius: 20px 20px 20px 0;
    width: 119px;
    padding: 8px;
    text-align: left;
    box-shadow: -3px 3px 10px 2px rgba(0, 0, 0, 0.25);

    z-index: -1;
    opacity: 0;
    pointer-events: none;
    &--show {
      z-index: inherit;
      opacity: 1;
      pointer-events: initial;
    }
  }

  .icon {
    position: absolute;
    right: -21px;
    top: -21px;
  }

  &--gray {
    background: $redVisited;
  }

  &--active {
    background: $green;
    opacity: 1;
    .name {
      color: $textDark;
    }
  }
  &:hover {
    box-shadow: none;
    .question {
      z-index: inherit;
      opacity: 1;
      pointer-events: initial;
    }
  }
}
