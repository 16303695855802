$white: #fff;
$black: #000;

$red: #e5226b;
$redHover: #ee6195;
$redVisited: #b9b0b3;

$green: #45f2a7;

$textDark: #262626;
