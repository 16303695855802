@import 'sass/vars';

.console-box {
  display: flex;
  align-items: center;

  h2 {
    color: $white;
    text-transform: uppercase;
    font-size: 5rem;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  &__description {
    margin-bottom: 30px;
    h2,
    p {
      margin: 0;
    }
    h2 {
      color: $red;
    }
    p {
      color: $white;
      font-size: 4.2rem;
      @media (max-width: 768px) {
        font-size: 1.4em;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 6px;
    }
  }

  &__draggable {
    margin-right: 30px;
    cursor: grab;
    &:focus {
      cursor: grabbing;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
