@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Oswald:wght@400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap");

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}
b,
strong {
  font-weight: bold;
}

* {
  font-family: "Oswald", sans-serif;
}
