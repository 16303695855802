@import 'sass/vars';

.path {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  path {
    fill: transparent;
    animation: show 1s linear 0.2s forwards;
  }
}

@keyframes show {
  from {
    fill: transparent;
  }
  to {
    fill: $red;
  }
}
